import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = () => (
    <Layout>
        <SEO title="404: Not found" />
        <h1 className="header text-4xl">404: NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... how sad.</p>
    </Layout>
);

export default NotFoundPage;
